<template>

  <vx-card style="padding-left: 10px">
      <vs-button v-if="subscriptionData" id="restore-btn" class="mb-4 md:mb-3" @click="restoreHandler" >Restore</vs-button>
       <vue-json-pretty v-if="subscriptionData"
      :path="'res'"
      :data="subscriptionData">
    </vue-json-pretty>

  </vx-card>
</template>
<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import VueJsonPretty from 'vue-json-pretty'
import moment from "moment";
export default {
    components: {
        "v-select": vSelect,
        VueJsonPretty
    },
    data() {
        return {
            subscriptionData: null,
        }
    },
    methods: {
       ...mapActions("subscription", ["fetchSubscriptionDetails","restoreSubscription"]),
        getSubscriptionDetils(id) {
            let self = this;
            this.fetchSubscriptionDetails(id).then((res) => {
                this.subscriptionData = res.data.data;
            });
        },
        restoreHandler(){
            this.subscriptionData=null
            let subscriptionId = this.$route.params.id;
            this.restoreSubscription(subscriptionId).then( (res) => {
                this.getSubscriptionDetils(subscriptionId)     
            });
        }
    },
    created() {
        let subscriptionId = this.$route.params.id;
        this.getSubscriptionDetils(subscriptionId);
    },
}
</script>